import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

import { AuthGuard } from './services/auth.service';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./home/home.module').then(m => m.HomePageModule)
  },
  {
    path: 'login',
    loadChildren: () => import('./login/login.module').then(m => m.LoginPageModule)
  },
  {
    path: 'register',
    loadChildren: () => import('./register/register.module').then(m => m.RegisterPageModule)
  },

  {
    path: 'gateway',
    loadChildren: () => import('./gateway/gateway.module').then(m => m.GatewayPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'packets',
    loadChildren: () => import('./gateway/packets/packets.module').then(m => m.PacketsPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'topics',
    loadChildren: () => import('./gateway/topics/topics.module').then(m => m.TopicsPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'team',
    loadChildren: () => import('./gateway/teams/teams.module').then(m => m.TeamsPageModule),
    canActivate: [AuthGuard]
  },


  {
    path: 'packets/new',
    loadChildren: () => import('./packet/meta/meta.module').then(m => m.MetaPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'packets/:id',
    loadChildren: () => import('./packet/packet.module').then(m => m.PacketPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: '404',
    loadChildren: () => import('./not-found.page').then(m => m.NotFoundPageModule)
  },


  // { path: '', redirectTo: 'home', pathMatch: 'full' },
  { path: ':**', redirectTo: '404', pathMatch: 'full' },
  { path: ':**/:**', redirectTo: '404', pathMatch: 'full' },
  { path: ':**/:**/:**', redirectTo: '404', pathMatch: 'full' },


];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
