import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormArray } from '@angular/forms';
@Component({
  selector: 'app-grid-card',
  templateUrl: './grid-card.component.html',
  styleUrls: ['./grid-card.component.scss'],
})
export class GridCardComponent implements OnInit {
  @Input() parentForm: FormGroup;
  @Input() index: number;
  @Input() currJudge: number;

  data: any;

  constructor() { }

  ngOnInit() {
    this.data = this.parentForm.value
  }

  get scoringArray(): FormArray {
    return this.parentForm.get('scoring') as FormArray
  }

}
