import * as firebase from 'firebase/app';
// import * as firebaseui from 'firebaseui';

// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebase: {
    apiKey: "AIzaSyBjQKwil220iu9YXeC_xHlG6dAEUmZSRdo",
    authDomain: "cj-gateway-3412a.firebaseapp.com",
    databaseURL: "https://cj-gateway-3412a.firebaseio.com",
    projectId: "cj-gateway-3412a",
    storageBucket: "cj-gateway-3412a.appspot.com",
    messagingSenderId: "259023580687"
  }
};

// export const uiAuthConfig: firebaseui.auth.Config = {
//   signInFlow: 'popup',
//   signInSuccessUrl: '/gateway',
//   signInOptions: [
//     firebase.auth.GoogleAuthProvider.PROVIDER_ID,
//     {
//       scopes: [
//         'public_profile',
//         'email',
//         'user_likes',
//         'user_friends'
//       ],
//       customParameters: {
//         'auth_type': 'reauthenticate'
//       },
//       provider: firebase.auth.FacebookAuthProvider.PROVIDER_ID
//     },
//     // firebase.auth.TwitterAuthProvider.PROVIDER_ID,
//     // firebase.auth.GithubAuthProvider.PROVIDER_ID,
//     // {
//     //   requireDisplayName: false,
//     //   provider: firebase.auth.EmailAuthProvider.PROVIDER_ID
//     // },
//     // firebase.auth.PhoneAuthProvider.PROVIDER_ID,
//     // firebaseui.auth.AnonymousAuthProvider.PROVIDER_ID
//   ],
//   tosUrl: '<your-tos-link>',
//   privacyPolicyUrl: '<your-privacyPolicyUrl-link>',
//   credentialHelper: firebaseui.auth.CredentialHelper.ACCOUNT_CHOOSER_COM
// };

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
