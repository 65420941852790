import { Injectable } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FormBuilder, FormGroup, FormControl, FormArray, Validators, AbstractControl } from '@angular/forms';
import { _Packet } from '../shared/gateway.interfaces';
// import { gzip, getSnapshot, getArrSnapshot } from './builtin'

@Injectable({
  providedIn: 'root'
})
export class PacketValidator {

  constructor(
    private fb: FormBuilder
  ) { }

  get fullForm(): FormGroup {
    return this.fb.group({
      meta: this.fb.group({
        completed: false,
        graded: true,
        individual: false,
        magic: false,
        graphicsFilepath: null,
        id: "",
        uid: "",
        name: this.fb.group({
          long: "",
          short: "",
          nick: ""
        }),
        problem: ["", Validators.required],
        division: ["", Validators.required],
        judges: [[], this.JudgeIdValidator],
        // judges: this.fb.array([], { validators: this.JudgeIdValidator }),
        teamid: [null, Validators.required],
        team: [[]],
        year: [null, Validators.required],
        location: ""
      }),
      packet: this.fb.group({
        challenges: this.fb.array(Array(16).fill('')),
        up: this.fb.group({
          challenge: null,
          text: ""
        }),
        solutions: this.fb.array(Array(16).fill('')),
        criteria: this.fb.array(Array(5).fill('')),
        grid: this.fb.group({
          grading: this.fb.group([
            this.fb.array([0, 0, 0, 0, 0]),
            this.fb.array([0, 0, 0, 0, 0]),
            this.fb.array([0, 0, 0, 0, 0]),
            this.fb.array([0, 0, 0, 0, 0]),
            this.fb.array([0, 0, 0, 0, 0]),
            this.fb.array([0, 0, 0, 0, 0]),
            this.fb.array([0, 0, 0, 0, 0]),
            this.fb.array([0, 0, 0, 0, 0])
          ]),
          text: this.fb.array(["", "", "", "", "", "", "", ""]),
          number: this.fb.array([0, 0, 0, 0, 0, 0, 0, 0]),
          total: this.fb.array([0, 0, 0, 0, 0, 0, 0, 0])
        }),
        ap: this.fb.group({
          solution: null,
          text: ""
        })
      }),
      scoring: this.fb.array([])
    })
  }
  get scoring(): FormGroup {
    return this.fb.group({
      judgeid: null,
      overall: this.fb.group({
        research: [null, Validators.max(10)],
        creativeStrength: [null, Validators.max(10)],
        futuristicThinking: [null, Validators.max(10)],
        total: null,
        comments: ""
      }),
      challenges: this.fb.group({
        comments: "",
        fluency: [null, Validators.max(10)],
        flexibility: [null, Validators.max(10)],
        clarity: [null, Validators.max(10)],
        feedback: this.fb.array(["", "", "", "", "", "", "", "", "", "", "", "", "", "", "", ""]),
        type: this.fb.array(["", "", "", "", "", "", "", "", "", "", "", "", "", "", "", ""]),
        duplicate: this.fb.array([null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null]),
        categories: this.fb.array([null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null]),
        original: this.fb.array([false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false]),
      }),
      up: this.fb.group({
        comments: '',
        cp: this.fb.group({
          text: '',
          score: [null, Validators.max(2)]
        }),
        stemAndKvp: this.fb.group({
          stemText: '',
          kvpText: '',
          score: [null, Validators.max(3)]
        }),
        purpose: this.fb.group({
          text: '',
          score: [null, Validators.max(3)]
        }),
        parameters: this.fb.group({
          topic: true,
          place: true,
          time: true,
          score: [null, Validators.max(2)]
        }),
        focus: [null, Validators.max(10)],
        adequacy: [null, Validators.max(10)],
      }),
      solutions: this.fb.group({
        comments: "",
        fluency: 10,
        elaboration: 10,
        flexibility: 10,
        feedback: this.fb.array(["", "", "", "", "", "", "", "", "", "", "", "", "", "", "", ""]),
        type: this.fb.array(["", "", "", "", "", "", "", "", "", "", "", "", "", "", "", ""]),
        duplicate: this.fb.array([null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null]),
        elab: this.fb.array([true, true, true, true, true, true, true, true, true, true, true, true, true, true, true, true]),
        categories: this.fb.array([null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null]),
        original: this.fb.array([false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false]),
      }),
      criteria: this.fb.group({
        comments: "",
        correctlyWritten: 5,
        applicabilityRelevance: 15,
        relevance: this.fb.array(["", "", "", "", ""]),
        cw: this.fb.array([true, true, true, true, true])
      }),
      grid: this.fb.group({
        comments: "",
        correctlyUsed: 5,
      }),
      ap: this.fb.group({
        comments: "",
        relevance: 5,
        effectiveness: 5,
        impact: 5,
        humaneness: 5,
        development: 10
      })
    })
  }
  // get challengesGroup(): FormGroup {
  //   return this.fb.group({
  //     comments: "",
  //     yes: true,
  //     categories: null,
  //     original: false,
  //     perhaps: false,
  //     why: false,
  //     duplicate: null,
  //     solution: false
  //   }, [(formGroup: FormGroup) => {
  //     //check if dup problem is entered
  //     return (formGroup.value.pwds_group == 'D' && !formGroup.value.dup) ? { needDupProb: true } : null
  //   }, (formGroup: FormGroup) => {
  //     //check if one of PWDS is checked if P/W/D/S is checked
  //     return (formGroup.value.pwds && !formGroup.value.pwds_group) ? { needPWDS: true } : null
  //   }, (formGroup: FormGroup) => {
  //     //check if problem is yes, there has to be a category
  //     return (formGroup.value.yes && (formGroup.value.categories === null || formGroup.value.categories.length == 0)) ? { needCat: true } : null
  //   }])
  // }
  // get solutionsGroup(): FormGroup {
  //   return this.fb.group({
  //     comments: '',
  //     relevant: true,
  //     elaboration: true,
  //     categories: null,
  //     original: false,
  //     perhaps: false,
  //     why: false,
  //     duplicate: null
  //   }, [(formGroup: FormGroup) => {
  //     //check if dup problem is entered
  //     return (formGroup.value.pwds_group == 'D' && !formGroup.value.dup) ? { needDupProb: true } : null
  //   }, (formGroup: FormGroup) => {
  //     //check if one of PWDS is checked if P/W/D/S is checked
  //     return (formGroup.value.pwds && !formGroup.value.pwds_group) ? { needPWDS: true } : null
  //   }, (formGroup: FormGroup) => {
  //     //check if solution is relevant, there has to be a category
  //     return (formGroup.value.relevant && (formGroup.value.categories === null || formGroup.value.categories.length == 0)) ? { needCat: true } : null
  //   }])
  // }
  // get criteriaGroup(): FormGroup {
  //   return this.fb.group({
  //     cw: true,
  //     relevance: this.fb.array(['', null])
  //   })
  // }
  // get gridGroup(): FormGroup {
  //   return this.fb.group({
  //     solution: this.fb.group({
  //       number: [null, Validators.max(16)],
  //       text: ''
  //     }),
  //     grading: this.fb.array(Array(5).fill([null, Validators.max(8)])),
  //     total: null
  //   })
  // }


  JudgeIdValidator(control: AbstractControl) {
    if (control.parent) {
      if (control.value.includes(null) && control.parent.get('graded').value) return { noJudge: true }
    }
    return null
  }

  getJudges(formGroup: _Packet): number[] {
    return formGroup.scoring.map(obj => obj.judgeid)
  }

  challengesArray(obj: FormGroup): FormArray {
    return obj.get(['challenges', 'data']) as FormArray
  }

  // generateChallenges(obj: FormGroup, num: number) {
  //   for (var i = 0; i < num; i++) {
  //     this.challengesArray(obj).push(this.challengesGroup)
  //   }
  // }
  // mapForm(control: string | string[], data: Packet): FormGroup {
  //   var form = new FormGroup({
  //     packet: this.fullForm.get('packet.' + control) as FormArray,
  //     //makes the scoring a snapshot array of control and judgeid
  //     scoring: this.fb.array(this.populateArray(this.scoring, data.scoring.length))
  //   })
  //   console.log(this.populateArray(this.scoring, data.scoring.length));
  //   return form
  // }
}
