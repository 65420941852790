import { Component, OnInit } from '@angular/core';
import { AuthService } from '../services/auth.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-user-popover',
  template: `
  <ion-item (click)="nav(['settings'])" button>
    <ion-icon ios='settings-outline' md='settings-sharp' slot="start" size="large"></ion-icon>
    <ion-label>Settings</ion-label>
  </ion-item>
  <ion-item (click)='logout()' button>
    <ion-icon ios='log-out-outline' md='log-out-sharp' slot="start" size="large"></ion-icon>
    <ion-label>Log Out</ion-label>
  </ion-item>`,
})
export class UserPopoverComponent implements OnInit {

  constructor(private authService: AuthService, private router: Router) { }

  ngOnInit() { }

  nav(page: string[]) {
    this.router.navigate(page)
  }

  logout() {
    this.authService.logout()
  }

}
