import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Storage } from "@ionic/storage";
import { Observable, Subscription } from 'rxjs';
import * as firebase from 'firebase/app';

import { _Packet, gUser } from '../shared/gateway.interfaces'
import { PacketValidator } from '../validators/packet.validator';
import { AuthService } from './auth.service';
import { AngularFireStorage } from '@angular/fire/storage';
import { AngularFireAuth } from '@angular/fire/auth';

@Injectable({
  providedIn: 'root'
})
export class FirestoreService {

  user: firebase.User;

  constructor(
    private afs: AngularFirestore,
    private fireStorage: AngularFireStorage,
    private pv: PacketValidator,
    private storage: Storage,
    private afAuth: AngularFireAuth,
    private authService: AuthService
  ) {
    this.afAuth.user.subscribe(user => {
      let $user: Subscription;
      if (user) {
        $user = this.afs.doc<gUser>('users/' + user.uid).valueChanges().subscribe(user => {
          this.storage.set('user', user)
        })
      } else {
        $user.unsubscribe()
        console.log('user did dun log out');
      }
    })
  }

  get packetNames(): any {
    return this.afs.collection('packets').snapshotChanges()
  }

  get errorMessages() {
    return this.afs.doc('meta/packet-msgs').valueChanges()
  }

  packetData(id: string): Observable<any> {
    return this.afs.doc('packets/' + id).valueChanges()
  }

  generateName(m: _Packet['meta']): _Packet['meta']['name'] {
    const problem = {
      P1: 'Practice Problem 1', P2: 'Practice Problem 2',
      Q: 'Qualifying', S: 'State', I: 'Internationals'
    }
    m.teamid = (m.teamid >= 100) ? m.teamid : 'M' + m.teamid.toString()
    const location = m.location ? m.location + ' ' : ''
    const short = location + m.division + m.teamid + ' ' + (m.problem.length == 2 ? m.problem : m.problem + '-') + m.year % 100
    const long = (location + m.division + m.teamid + ' '
      + problem[m.problem] + ' ' + (m.year - 1).toString() + '-'
      + (m.year % 100).toString());
    return {
      long: long,
      short: short,
      nick: m.name.nick
    }
  }

  addData(key: string, data: any, dir: string) {
    this.afs.doc('packets/' + dir).update({ [key]: data })
  }

  changeJudges(newData: _Packet['meta'], oldData: _Packet) {
    let o = oldData.meta.judges;
    let n = newData.judges

    let added = n.filter(id => !o.includes(id))
    let common = o.filter(id => n.includes(id))

    let newScoring = this.generateScoring(added)
    oldData.scoring.forEach(el => {
      if (common.includes(el.judgeid)) {
        newScoring.push(el)
      }
    })
    console.log(newScoring);

    this.addData('scoring', newScoring, newData.id)
    this.addData('meta', newData, newData.id)
  }

  addPacket(meta: _Packet['meta'], uid) {
    let gid = this.afs.createId()
    meta.id = gid
    meta.uid = uid
    meta.name = this.generateName(meta)
    meta.created = firebase.firestore.Timestamp.now();
    meta.lastEdited = firebase.firestore.Timestamp.now();

    //add packet
    this.afs.doc('packets/' + gid).set({
      meta: meta,
      packet: this.pv.fullForm.value.packet,
      scoring: this.generateScoring(meta.judges)
    })
    //update User
    this.afs.doc('users/' + uid).update({
      packets: firebase.firestore.FieldValue.arrayUnion(gid)
    })
  }

  generateScoring(ids: number[]) {
    return ids.map(id => {
      let s = this.pv.scoring.value
      s.judgeid = id
      return s
    })
  }

}
