import { Component, OnInit, Input } from '@angular/core';
import { PopoverController } from '@ionic/angular';
import { PacketsPage } from "../../gateway/packets/packets.page";

@Component({
  selector: 'app-sort-popover',
  templateUrl: './sort-popover.component.html',
  styleUrls: ['./sort-popover.component.scss'],
})
export class SortPopoverComponent implements OnInit {

  @Input() sortBy: string;

  constructor(private popoverController: PopoverController) { }

  ngOnInit() { }

  dismiss(sort: string) {
    this.sortBy = sort;
    this.popoverController.dismiss(this.sortBy)
  }
}
