import { Component, OnInit, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { AngularFirestore } from '@angular/fire/firestore';
import { FirestoreService } from '../services/firestore.service';

@Component({
  selector: 'app-valid',
  template: `
  <div class="validation-errors">
    <ng-container *ngFor="let val of messages">
      <div class="error-message" *ngIf="control.hasError(val.type) && control.touched">
        <ion-icon name="information-circle-outline"></ion-icon> {{ val.message }}
      </div>
    </ng-container>
  </div>`,
  styles: [`.error-message {
    color: red;
    font-size: 14px;
    margin-left: 10px;
    margin-top: 10px;
  }`]
})
export class ValidComponent implements OnInit {

  @Input() form: FormGroup;
  @Input() name: any;

  messages: {};
  control: any;

  constructor(
    public afs: AngularFirestore,
    public fireService: FirestoreService
  ) { }

  ngOnInit() {
    this.control = this.form.get(this.name)

    this.fireService.errorMessages.subscribe((data: object) => {
      this.messages = this.getMessages(data, this.name.split('.'))
    })
  }

  getMessages(obj: object, path: any): object {
    var subobj = obj[path[0]]
    return path.length == 1 ? subobj : this.getMessages(subobj, path.slice(1))
  }
}
