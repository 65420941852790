import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormArray } from '@angular/forms';

@Component({
  selector: 'app-problems-card',
  templateUrl: './problems-card.component.html',
  styleUrls: ['./problems-card.component.scss'],
})
export class ProblemsCardComponent implements OnInit {

  @Input() parentForm: FormGroup;
  @Input() index: number;
  @Input() currJudge: number;
  @Input() packetOnly: boolean = true;

  data: any;
  categories: string[] = require('../../shared/categories.json')


  constructor() { }

  ngOnInit() {
    this.data = this.parentForm.value
  }

  get scoringArray(): FormArray {
    return this.parentForm.get('scoring') as FormArray
  }

}
