import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { PopoverController, IonChip } from '@ionic/angular';
import { _Packet } from 'src/app/shared/gateway.interfaces';

@Component({
  selector: 'app-filter-popover',
  templateUrl: './filter-popover.component.html',
  styleUrls: ['./filter-popover.component.scss'],
})
export class FilterPopoverComponent implements OnInit {

  @Input() filterBy: string;
  @Input() event: MouseEvent
  @Input() packets: _Packet[] | Promise<_Packet[]>;

  judges: number[] = [];
  problems: string[] = [];
  divisions: string[] = [];
  years: number[] = [];

  constructor(private popoverController: PopoverController) { }

  async ngOnInit() {
    this.packets = await this.packets
    //Finds all judge IDs and then filters the unique ones
    this.packets.forEach(packet => {
      this.judges.push(...packet.meta.judges)
      this.problems.push(packet.meta.problem)
      this.divisions.push(packet.meta.division)
      this.years.push(packet.meta.year)
    })
    this.judges = [...new Set(this.judges)]
    this.years = [...new Set(this.years)]
    this.problems = ["P1", "P2", "Q", "S", "I"].filter(el => this.problems.includes(el))
    this.divisions = ["J", "M", "S", "A"].filter(el => this.divisions.includes(el))

  }

  dismiss(filter: string, input?: any) {
    this.filterBy = filter;

    if (input) {
      setTimeout(() => {
        this.popoverController.dismiss({ filter: this.filterBy, input })
      }, 1);
    } else {
      this.popoverController.dismiss(this.filterBy)
    }
  }

  async presentPopover(keyword: string, ev: MouseEvent) {
    const data = {
      judge: this.judges,
      problem: this.problems,
      division: this.divisions,
      year: this.years
    }
    const popover = await this.popoverController.create({
      component: SelectPopoverComponent,
      componentProps: {
        data: data[keyword],
        keyword
      },
      event: ev,
      showBackdrop: false,
      cssClass: 'select-popover'
    });

    await popover.present();

    popover.onWillDismiss().then(data => {
      if (data.data == undefined) this.filterBy = ""
    })
    popover.onDidDismiss().then(data => {
      if (data.data) {
        this.dismiss(keyword, data.data)
      }
    })
  }


}



@Component({
  selector: 'app-select-popover',
  template: `
    <p>Select {{keyword}}s to filter by</p>
    <div class='flex-chip'>
      <ion-chip *ngFor="let el of data" (click)="add(el, $event)" [ngClass]="{'chip': !inFiltered(el), 'chip-selected': inFiltered(el)}">
        {{el}}
      </ion-chip>
    </div>
    <ion-button style='margin: 10px;' (click)="popoverController.dismiss(filtered)" expand="block" fill="outline" shape="round">
      Filter
    </ion-button>
  `,
  styleUrls: ["./filter-popover.component.scss"]
})

export class SelectPopoverComponent {

  @Input() data: any[]
  @Input() keyword: string
  filtered: any[] = []

  constructor(private popoverController: PopoverController) { }

  inFiltered(el: any) {
    return this.filtered.includes(el)
  }

  add(el: any, event: Event) {
    if (this.inFiltered(el)) {
      const i = this.filtered.indexOf(el)
      if (i !== -1) this.filtered.splice(i, 1);
    } else {
      this.filtered.push(el)
    }
  }
}