import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormArray } from '@angular/forms';

@Component({
  selector: 'app-criteria-card',
  templateUrl: './criteria-card.component.html',
  styleUrls: ['./criteria-card.component.scss'],
})
export class CriteriaCardComponent implements OnInit {

  @Input() parentForm: FormGroup;
  @Input() index: number;
  @Input() currJudge: number;
  @Input() packetOnly: boolean = true;


  data: any;


  constructor() { }

  ngOnInit() {
    this.data = this.parentForm.value
  }

  get scoringArray(): FormArray {
    return this.parentForm.get('scoring') as FormArray
  }

}
