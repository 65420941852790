import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PipesModule } from '../shared/pipe.module';

import { ValidComponent } from './valid.component';
import { UserPopoverComponent } from './user-popover.component';
import { ProblemsCardComponent } from './problems-card/problems-card.component';
import { SolutionsCardComponent } from './solutions-card/solutions-card.component';
import { ChipInputComponent } from './chip-input/chip-input.component';
import { CriteriaCardComponent } from './criteria-card/criteria-card.component';
import { GridCardComponent } from './grid-card/grid-card.component';
import { SortPopoverComponent } from './sort-popover/sort-popover.component';
import { FilterPopoverComponent, SelectPopoverComponent } from './filter-popover/filter-popover.component';

var components = [
  ProblemsCardComponent,
  SolutionsCardComponent,
  CriteriaCardComponent,
  GridCardComponent,
  ValidComponent,
  UserPopoverComponent,
  SortPopoverComponent,
  FilterPopoverComponent,
  SelectPopoverComponent,
  ChipInputComponent
]

@NgModule({
  declarations: components,
  imports: [
    CommonModule,
    IonicModule,
    FormsModule,
    ReactiveFormsModule,
    PipesModule
  ],
  entryComponents: [UserPopoverComponent, SortPopoverComponent, FilterPopoverComponent, SelectPopoverComponent],
  exports: components
})
export class ComponentsModule { }
