import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot } from "@angular/router";
import { Storage } from '@ionic/storage';

import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireAuth } from "@angular/fire/auth";
import { AngularFireStorage } from '@angular/fire/storage';
import * as firebase from 'firebase';

import { map } from 'rxjs/operators'
import { gUser } from '../shared/gateway.interfaces';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(
    private router: Router,
    private storage: Storage,
    public afAuth: AngularFireAuth,
    private afs: AngularFirestore,
    private fireStorage: AngularFireStorage
  ) { }

  get getUser(): Promise<gUser> {
    return this.storage.get('user')
  }

  update(data: firebase.User) {
    if (data) {
      return this.afs.doc<gUser>('users/' + data.uid).get().toPromise().then(user => {
        return this.storage.set('user', user.data())
      })
    } else {
      this.storage.set('user', data)
    }
  }

  async login(email: string, password: string) {
    await this.afAuth.auth.signInWithEmailAndPassword(email, password).then(async data => {
      await this.update(data.user)
    })
  }

  logout() {
    this.afAuth.auth.signOut();
    this.update(null)
    localStorage.setItem('theme', 'light')
    this.router.navigate([''])
  }

  async signup(email: string, name: string, password: string) {
    await this.afAuth.auth.createUserWithEmailAndPassword(email, password)
      .then(data => {
        data.user.displayName = name
        this.createUser(data.user)
      }).catch(e => {
        console.log(e);
      });
  }


  createUser(user: firebase.User) {
    const data: gUser = {
      email: user.email,
      name: user.displayName,
      packets: [],
      uid: user.uid
    }
    this.afs.doc('users/' + user.uid).set(data)
    this.fireStorage.ref('')
    this.storage.set('user', data)
  }

}


@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(
    private router: Router,
    private auth: AuthService
  ) { }

  canActivate(route: ActivatedRouteSnapshot) {
    return this.auth.afAuth.authState.pipe(
      map(data => {
        // console.log(data);
        if (!data) this.router.navigate(['']);
        return Boolean(data)
      })
    )
  }

}
