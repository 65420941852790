import { NgModule, Injectable } from '@angular/core';
import { CommonModule } from '@angular/common';

import { Validators, FormBuilder, FormGroup, FormControl } from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class LoginValidator {
  password_group: FormGroup;
  login_form: FormGroup;
  register_form: FormGroup;

  email_regex: RegExp = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i

  constructor(private fb: FormBuilder) {
    this.login_form = this.fb.group({
      email: ['', Validators.required],
      password: ['', Validators.required],

    });

    this.register_form = this.fb.group({
      email: ['', [Validators.required, Validators.pattern(this.email_regex)]],
      name: ['', [
        Validators.required,
        Validators.minLength(4),
        Validators.maxLength(20),
        Validators.pattern('^[a-zA-Z0-9-_]+$'),
        LoginValidator.validUsername
      ]],
      matching_passwords: this.fb.group({
        password: ['', [
          Validators.minLength(5),
          Validators.required,
          Validators.pattern('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9]).+$')
        ]],
        confirm_password: ['', Validators.required],
      }, (formGroup: FormGroup) => {
        return LoginValidator.areEqual(formGroup);
      })
    });
  }

  static validUsername(fc: FormControl) {

    if (fc.value.toLowerCase() === "abc123" || fc.value.toLowerCase() === "123abc") {
      return {
        validUsername: true
      };
    } else {
      return null;
    }
  }

  static areEqual(formGroup: FormGroup) {
    let val;
    let valid = true;

    for (let key in formGroup.controls) {
      if (formGroup.controls.hasOwnProperty(key)) {
        let control: FormControl = <FormControl>formGroup.controls[key];

        if (val === undefined) {
          val = control.value
        } else {
          if (val !== control.value) {
            valid = false;
            break;
          }
        }
      }
    }

    if (valid) {
      return null;
    }

    return {
      areEqual: true
    };
  }

  validation_messages = {
    'name': [
      { type: 'required', message: 'Username is required.' },
      { type: 'minlength', message: 'Username must be at least 4 characters long.' },
      { type: 'maxlength', message: 'Username cannot be more than 20 characters long.' },
      { type: 'pattern', message: 'Your username must contain only numbers and letters.' },
      { type: 'validUsername', message: 'Your username has already been taken.' }
    ],
    'email': [
      { type: 'required', message: 'Email is required.' },
      { type: 'pattern', message: 'Please enter a valid email.' }
    ],
    'password': [
      { type: 'required', message: 'Password is required.' },
      { type: 'minlength', message: 'Password must be at least 5 characters long.' },
      { type: 'pattern', message: 'Your password must contain at least one uppercase, one lowercase, and one number.' }
    ],
    'confirm_password': [
      { type: 'required', message: 'Confirm password is required.' }
    ],
    'matching_passwords': [
      { type: 'areEqual', message: 'Passwords don\'t match.' }
    ]
  };

}
