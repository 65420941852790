import { Component, OnInit, Input, ViewChild, Output, EventEmitter, forwardRef } from '@angular/core';
import { IonInput } from '@ionic/angular';
import { NG_VALUE_ACCESSOR, ControlValueAccessor, ControlContainer } from '@angular/forms';
import { _Packet } from '../../shared/gateway.interfaces';

@Component({
  selector: 'ion-chip-input',
  templateUrl: './chip-input.component.html',
  styleUrls: ['./chip-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ChipInputComponent),
      multi: true
    }
  ]
})
export class ChipInputComponent implements OnInit, ControlValueAccessor {
  @Input('seperatorId') sid: number;
  @Input() label: string;
  @Input() color: number;
  @Input() placeholder: number;
  @Input() id: string;
  @Input('length') max: number;
  @Input('numbersOnly') nums: boolean;
  @Input() _list: (string | number)[] = [];

  @ViewChild(IonInput, { static: false }) input: IonInput;

  warn = false

  constructor(private controlContainer: ControlContainer) { }

  ngOnInit() {
    if (this.sid == undefined) {
      console.error('Must supply a seperatorId')
    }
    this.max = this.max || Infinity
    this.controlContainer.valueChanges.subscribe((data: _Packet['meta']) => {
      this.list = data[this.id]
      this.checkLength()
    })

    document.querySelector("ion-chip-input#" + this.id).addEventListener('keydown', (event: KeyboardEvent) => {
      let v = this.input.value.toString();
      let k = event.keyCode

      //adds Chip if seperator key is pressed
      if (k == this.sid && v.length) this.addEl(v)
      //steps back from the delete stage
      else if (this.warn && event.key.length == 1) {
        this.warn = false
      }
      //deletes el or warns to delete
      else if (k == 8 && !v) {
        if (this.warn) {
          this.deleteEl(-1)
          this.warn = false
        } else {
          this.warn = true
        }
      }
      this.checkLength()
    })
  }

  get list() {
    return this._list;
  }

  set list(val) {
    this._list = val;
    this.propagateChange(this._list);
  }

  addEl(el: string) {
    if (this.list.length < this.max) {
      this.list.push(this.nums ? parseInt(el) : el);
    }
    this.propagateChange(this.list);
    this.input.value = ''
  }
  deleteEl(index: number) {
    this.list.splice(index, 1);
    this.propagateChange(this.list);

  }

  numsVal(event: any) {
    const pattern = /[0-9]/;
    let inputChar = String.fromCharCode(event.charCode);

    if (!pattern.test(inputChar) && this.nums) {
      // invalid character, prevent input
      event.preventDefault();
    }
  }

  checkLength() {
    if (this.list.length == this.max) {
      this.input.readonly = true
    } else {
      this.input.readonly = false
    }
  }


  writeValue(value: any) {
    if (value !== undefined) {
      this._list = value;
    }
  }
  propagateChange = (_: any) => { };

  registerOnChange(fn) {
    this.propagateChange = fn;
  }

  registerOnTouched() { }

  setDisabledState?(isDisabled: boolean): void {
    throw new Error("Method not implemented.");
  }
}
